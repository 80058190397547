export const setGclid = () => {
  const params = new URLSearchParams(document.location.search)
  const gclidId = params.get("gclid")
  if (typeof window !== undefined && gclidId) {
    window.sessionStorage.setItem("gclid", gclidId)
  }
}

export const getGclid = () => {
  const gclid = window.sessionStorage.getItem("gclid")
  return gclid
}
